// ** Reducers Imports
import navbar from "olympus-frontend/src/redux/navbar";
import layout from "olympus-frontend/src/redux/layout";
import auth from "olympus-frontend/src/redux/authentication";
import app from "olympus-frontend/src/redux/app";
import users from "olympus-frontend/src/views/user/store";
import {lazy} from "react";
import {
    Book,
    Cpu,
    Home,
    Grid,
    Layers,
    Map,
    Server,
    Tool,
    Play,
    Key,
    AlignCenter,
    AlignJustify,
    Table, Database,
    Type, Archive, Radio, Activity, Circle, Box
} from 'react-feather'

const backendBasePath = process.env.REACT_APP_HOST_API

export default {
    auth: {
        basePath: `${backendBasePath}auth`,
        loginEndpoint: `${backendBasePath}auth/login`,
        registerEndpoint: `${backendBasePath}auth/account/registration`,
        refreshEndpoint: `${backendBasePath}auth/login/refresh`,
        logoutEndpoint: `${backendBasePath}auth/logout`,
        tokenType: "Bearer",
        storageTokenKeyName: "accessToken",
        storageRefreshTokenKeyName: "refreshToken",
        sendVerificationEmailEndpoint: `${backendBasePath}auth/account/email-verification/send-email`,
        verifyEmailEndpoint: `${backendBasePath}auth/account/email-verification`,
        activationAccountEndpoint: `${backendBasePath}auth/account/activation/send-email`,
        forgotPasswordEndpoint: `${backendBasePath}auth/password/recovery`,
        changePasswordEndpoint: `${backendBasePath}auth/password/reset`,
        changeTempPasswordEndpoint: `${backendBasePath}auth/password/change`
    },
    API: {
        usersAPI: `${backendBasePath}users`,
        activityWorksteps: `${backendBasePath}assets/activity_worksteps`,
        devices: `${backendBasePath}assets/devices`,
        deviceTypes: `${backendBasePath}assets/device_types`,
        panels: `${backendBasePath}assets/panels`,
        panelModels: `${backendBasePath}assets/panel_models`,
        tracked_panels: `${backendBasePath}assets/tracked_panels`,
        metrics: `${backendBasePath}assets/metrics`,
        metricType: `${backendBasePath}assets/metric_types`,
        locations: `${backendBasePath}assets/locations`,
        models: `${backendBasePath}assets/device_models`,
        manufacturers: `${backendBasePath}assets/device_manufacturers`,
        conversionFunctions: `${backendBasePath}assets/conversion_functions`,
        analysis: `${backendBasePath}analysis`
    },
    reduxReducers: {auth, app, users, navbar, layout},
    routing: {
        routes: [
            {
                path: "/home",
                component: lazy(() => import("../assetpro/home")),
                meta: {
                    publicRoute: false,
                    administrator: true
                },
            },
            {
                path: "/editor",
                component: lazy(() => import("../assetpro/editor")),
                meta: {
                    publicRoute: false,
                    administrator: true
                },
            },
            {
                path: "/dashboard",
                component: lazy(() => import("../assetpro/dashboard")),
                meta: {
                    publicRoute: false,
                    administrator: true
                },
            },
            // {
            //     path: "/history",
            //     component: lazy(() => import("../assetpro/history")),
            //     meta: {
            //         publicRoute: true,
            //         administrator: true
            //     },
            // },
            {
                path: "/test_analysis/:test_id",
                component: lazy(() => import("../assetpro/analysis")),
                meta: {
                    publicRoute: false,
                    administrator: true
                },
            },
            {
                path: "/locations/view/:id",
                component: lazy(() => import("../assetpro/anagrafica/locations/view/index")),
                meta: {
                    publicRoute: false,
                    administrator: true
                },
            },
            {
                path: "/locations",
                component: lazy(() => import("../assetpro/anagrafica/locations")),
                meta: {
                    publicRoute: false,
                    administrator: true
                },
            },
            {
                path: "/devices",
                component: lazy(() => import("../assetpro/anagrafica/devices/index")),
                meta: {
                    publicRoute: false,
                    administrator: true
                },
            },
            {
                path: "/metrics",
                component: lazy(() => import("../assetpro/anagrafica/metrics/index")),
                meta: {
                    publicRoute: false,
                    administrator: true
                },
            },
            {
                path: "/metric_types",
                component: lazy(() => import("../assetpro/anagrafica/metric_types/index")),
                meta: {
                    publicRoute: false,
                    administrator: true
                },
            },
            {
                path: "/models",
                component: lazy(() => import("../assetpro/anagrafica/models/index")),
                meta: {
                    publicRoute: false,
                    administrator: true
                },
            },
            {
                path: "/manufacturers",
                component: lazy(() => import("../assetpro/anagrafica/manufacturer/index")),
                meta: {
                    publicRoute: false,
                    administrator: true
                },
            },
            {
                path: "/conversion_functions",
                component: lazy(() => import("../assetpro/anagrafica/conversion_functions/index")),
                meta: {
                    publicRoute: false,
                    administrator: true
                },
            },
            {
                path: "/login",
                component: lazy(() => import("olympus-frontend/src/views/Login")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/error",
                component: lazy(() => import("olympus-frontend/src/views/Error")),
                layout: "BlankLayout",
                meta: {
                    publicRoute: true,
                },
            },
            {
                path: "/recovery-password",
                component: lazy(() => import("olympus-frontend/src/views/ForgotPassword")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/change-password",
                component: lazy(() => import("olympus-frontend/src/views/ChangePassword")),
                layout: "BlankLayout",
                meta: {}
            },
            {
                path: "/reset-password/:token",
                component: lazy(() => import("olympus-frontend/src/views/ResetPasswordBasic")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/send-email-verification",
                component: lazy(() => import("olympus-frontend/src/views/VerifyEmailBasic")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/verify-email/:token",
                component: lazy(() => import("olympus-frontend/src/views/VerificationEmailLanding")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/polling-activation",
                component: lazy(() => import("olympus-frontend/src/views/PollingActivation")),
                layout: "BlankLayout",
                meta: {
                    authRoute: true,
                },
            },
            {
                path: "/support",
                component: lazy(() => import("../assetpro/supporto")),
                meta: {
                    publicRoute: true,
                    administrator: true
                },
            },
        ],
        defaultRoute: "/dashboard",
    },
    layout: {
        isRTL: false,
        skin: "light", // light, dark, bordered, semi-dark
        routerTransition: "fadeIn", // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
        type: "vertical", // vertical, horizontal
        contentWidth: "full", // full, boxed
        menu: {
            isHidden: false,
            isCollapsed: true,
        },
        navbar: {
            // ? For horizontal menu, navbar type will work for navMenu type
            type: "static", // static , sticky , floating, hidden
            backgroundColor: "", // BS color options [primary, success, etc]
        },
        footer: {
            type: "static", // static, sticky, hidden
        },
        customizer: false,
        navbarNavigation: [
            {
                header: 'Main',
                meta: {
                    administrator: true
                },
            },
            // {
            //     id: "home",
            //     title: "Home",
            //     icon: <Home size={20}/>,
            //     navLink: "/home",
            //     meta: {
            //         administrator: true
            //     }
            // },
            {
                id: "dashboard",
                title: "Dashboard",
                icon: <Map size={20}/>,
                navLink: "/dashboard",
                meta: {
                    administrator: true
                }
            },
            {
                id: "registry",
                title: "Registry",
                icon: <Table size={20}/>,
                navLink: "",
                meta: {
                    administrator: true
                },
                children: [
                    {
                        id: "locations",
                        title: "Locations",
                        icon: <Circle size={12}/>,
                        navLink: '/locations',
                        meta: {
                            administrator: true
                        },
                    },
                    {
                        id: "devices",
                        title: "Devices",
                        icon: <Circle size={12}/>,
                        navLink: '/devices',
                        meta: {
                            administrator: true
                        },
                    },
                    {
                        id: "models",
                        title: "Models",
                        icon: <Circle size={12}/>,
                        navLink: '/models',
                        meta: {
                            administrator: true
                        },
                    },
                    {
                        id: "manufacturers",
                        title: "Manufacturers",
                        icon: <Circle size={12}/>,
                        navLink: '/manufacturers',
                        meta: {
                            administrator: true
                        },
                    },
                    {
                        id: "conversion_functions",
                        title: "Conversion Functions",
                        icon: <Circle size={12}/>,
                        navLink: '/conversion_functions',
                        meta: {
                            administrator: true
                        },
                    },
                    {
                        id: "metric_types",
                        title: "Metric Types",
                        icon: <Circle size={12}/>,
                        navLink: '/metric_types',
                        meta: {
                            administrator: true
                        },
                    },
                    {
                        id: "metrics",
                        title: "Metrics",
                        icon: <Circle size={12}/>,
                        navLink: '/metrics',
                        meta: {
                            administrator: true
                        },
                    },
                ]
            },
            {
                id: "contabilizzazione",
                title: "Contabilizzazione",
                icon: <Layers size={12}/>,
                navLink: "/mammt",
                meta: {
                    administrator: true
                }
            },
            // {
            //     id: "history",
            //     title: "History",
            //     icon: <Archive size={20}/>,
            //     navLink: '/history',
            //     meta: {
            // 		administrator: true
            //     },
            // },
            {
                header: 'Support',
                meta: {
                    administrator: true
                },
            },
            {
                id: "manuale",
                title: "User Manual",
                icon: <Book size={20}/>,
                navLink: '/support',
                meta: {
                    administrator: true
                },
            },
        ],
        scrollTop: true, // Enable scroll to top button
    },
    app: {
        debugMode: true,
        appTitle: "",
        appIdentifier: "assetpro",
        appLogoImage: {
            dark: "ienco_logo.png",
            light: "ienco_logo.png"
        },
        appLogoImage2: {
            dark: "logo Enel.svg",
            light: "logo-enel-white.svg"
        },
        useSocials: false,
        useForgotPasswordLink: true,
        useRegisterLink: false,
        loginIllustration: "login-v2-dark.svg",
        user_groups: ['administrator'],
        default_lang: 'it'
    },
}
