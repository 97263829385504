// ** Olympus
import "olympus-frontend/src/@core/components/ripple-button";
import "olympus-frontend/src/@core/scss/react/libs/toastify/toastify.scss";
import initialization from 'olympus-frontend/src/configs/il8n/loader'
import 'olympus-frontend/src/@core/scss/react/libs/flatpickr/flatpickr.scss'

// ** Olympus Core styles
import "olympus-frontend/src/@core/assets/fonts/feather/iconfont.css";
import "olympus-frontend/src/@core/scss/core.scss";
import "olympus-frontend/src/assets/scss/style.scss";

// ** React Imports
import {lazy, Suspense} from "react";
import ReactDOM from "react-dom";
// ** Redux Imports
import {Provider} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";

import {ToastContainer} from "react-toastify";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** App --
import "./index.scss";
import appConfig from "./configs/appConfig";
import './assetpro/components/component_extension.scss'
import 'reflect-metadata';

// I18n Configuration
// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import lazyWithRetry from 'olympus-frontend/src/components/lazyAppErrorRetry'


// ** Lazy load app
const LazyApp = lazyWithRetry(() => import("./App"));

const store = configureStore({
  reducer: appConfig.reduxReducers,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

const i18n = initialization(appConfig.app.default_lang)

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={null}>
      {/* <AbilityContext.Provider value={ability}> */}
        {/* <ThemeContext> */}
          <LazyApp />
          <ToastContainer newestOnTop />
        {/* </ThemeContext> */}
      {/* </AbilityContext.Provider> */}
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
